@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  user-select: none;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(225, 241, 231, 1);
}

@supports (-webkit-touch-callout: none) {
  [id="container"] {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}

.footer-bottom {
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
}

.header-top {
  margin-top: 80px;
  margin-top: constant(safe-area-inset-bottom);
  margin-top: env(safe-area-inset-bottom);
}

.text-default {
  color: black;
}

.text-primary {
  color: rgb(161 199 184);
}

.text-title {
  color: rgba(134, 163, 151, 1);
}

.border-primary {
  border-color: rgba(177, 211, 197, 1);
}

.border-gray {
  border-color: rgba(165, 165, 165, 0.2);
}

/* svg {
  fill: rgba(var(--primary-color), 1);
} */

.primary {
  background-color: rgb(161 199 184);
}

.primary-opacity {
  background-color: rgba(177, 211, 197, 0.2);
}

.gray-opacity {
  background-color: rgba(165, 165, 165, 0.2);
}

.secondary {
  background-color: rgba(225, 241, 231, 1);
}

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

body {
  font-family: "Pretendard";
}

/* #e1f1e7 #b1d3c5 #cfddbe #e4be83 */

.loading {
  display: inline-block;
  position: relative;
  width: 51px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: #968787;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 10px;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
  background: #e1f1e7;
  height: 4px;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  background: #e1f1e7;
  height: 4px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -8px; /* Centers thumb on the track */
  background-color: #a1c7b8;
  height: 20px;
  width: 16px;
}

/***** Thumb Styles *****/
/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
  background-color: #a1c7b8;
  height: 20px;
  width: 16px;
}
